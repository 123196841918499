import { CreatePostCollection } from '@/Interfaces/I_PostCollection';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Switch, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { uploadPostCollectionImageUrl } from '@/Axios/api/Media';
import { UploadFile } from 'antd/es/upload';
import { UploadChangeParam } from 'antd/lib/upload';
import { createCollection } from '@/Redux/slices/PostCollectionSlice';
import { useAppDispatch } from '@/Hooks/useAppRedux';
import { customAxios } from '../axios';

interface FormData {
  name: string;
  disabled: boolean;
  image: UploadFile;
}

const initialFormData = {
  name: '',
  disabled: false,
};

const uploadImageUrl = customAxios.defaults.baseURL + uploadPostCollectionImageUrl;
const headers = {
  authorization: customAxios.defaults.headers.common.Authorization,
};

const SubmitButton = ({ disabled }: { disabled: boolean }) => (
  <Form.Item noStyle>
    <Button type="primary" htmlType="submit" disabled={disabled} form="createCollectionForm">
      Save
    </Button>
  </Form.Item>
);

const CreateCollectionModal = ({
  isModalVisible,
  setModalVisible,
}: {
  isModalVisible: boolean;
  setModalVisible: (value: boolean) => void;
}) => {
  const dispatch = useAppDispatch();

  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const onSaveClicked = (formData: FormData) => {
    const postCollection: CreatePostCollection = {
      name: formData.name,
      disabled: formData.disabled,
      imageId: formData.image.response[0].imageId,
      imageUrl: formData.image.response[0].imageUrl,
    };

    dispatch(createCollection(postCollection));
    setModalVisible(false);
    setFileList([]);
    form.resetFields();
  };

  const onFileChanged = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'done') {
      return info.file;
    }

    return null;
  };

  const onClose = () => {
    setModalVisible(false);
    setFileList([]);
    form.resetFields();
  };

  return (
    <Modal
      title="New Collection"
      open={isModalVisible}
      footer={<SubmitButton disabled={!submittable} />}
      onCancel={onClose}
    >
      <Form
        form={form}
        preserve={false}
        autoComplete="off"
        onFinish={onSaveClicked}
        id="createCollectionForm"
        initialValues={initialFormData}
      >
        <Form.Item
          name="image"
          label="Image"
          getValueFromEvent={onFileChanged}
          validateTrigger={['onRemove']}
          rules={[{ required: true, message: 'Image is required' }]}
        >
          <Upload
            listType="picture-card"
            action={uploadImageUrl}
            headers={headers}
            name="images"
            onChange={(info: UploadChangeParam<UploadFile>) => setFileList(info.fileList)}
            maxCount={1}
            fileList={fileList}
          >
            {fileList.length >= 1 ? null : (
              <button style={{ border: 0, background: 'none', cursor: 'pointer' }} type="button">
                <PlusOutlined />
                <span>Upload</span>
              </button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="disabled" label="Disable" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCollectionModal;
