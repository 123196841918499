import React, { useCallback, useEffect, useState } from 'react';
import { Button, Empty, Popconfirm, Table, Tag } from 'antd';
import timeTranslate from '@/Utils/timeTranslate';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import {
  getAffiliateBudgetData,
  patchAffiliateBudgetRecalculate,
} from '@/Redux/slices/AffiliateSlice';
import AffiliateCreateBudgetModal from '@/Components/AffiliateCreateBudgetModal';

const KocBudgetPool = () => {
  const dispatch = useAppDispatch();
  const { kocBudgetData } = useAppSelector((state) => state.affiliate.value);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [isCreateBudgetVisible, setIsCreateBudgetVisible] = useState(false);

  const loading = useAppSelector((state) => state.affiliate.loading);

  useEffect(() => {
    dispatch(
      getAffiliateBudgetData({
        post_type: 'NORMAL',
        page_no: currentPage - 1,
        page_size: pageSize,
      }),
    );
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    if (!loading && kocBudgetData.data) {
      setTotalPage(kocBudgetData.pagination.total);
    }
  }, [loading, kocBudgetData.data, kocBudgetData.pagination.total]);

  const tagClickEvent = useCallback(
    (id: number) => {
      dispatch(patchAffiliateBudgetRecalculate(id));
    },
    [dispatch],
  );

  // api not support sort in this phase
  // TODO: add sort in api
  const columns = [
    {
      title: 'Budget Id',
      dataIndex: 'id',
      key: 'id',
      width: 103,
      render: (text: number) => <p className="h-6 line-clamp-1 break-all m-0">{text}</p>,
      // sorter: true,
    },
    {
      title: 'Budget Type',
      key: 'budgetType',
      width: 103,
      render: () => <p className="h-6 line-clamp-1 break-all m-0">KOC</p>,
    },
    {
      title: 'Allocated Amount',
      dataIndex: 'allocateAmount',
      key: 'allocateAmount',
      width: 103,
    },
    {
      title: 'Snapshot Spent Amount',
      dataIndex: 'snapshotSpentAmount',
      key: 'snapshotSpentAmount',
      render: (snapshotSpentAmount: number) => (
        <div className="flex">{snapshotSpentAmount ?? '0'}</div>
      ),
      width: 103,
    },
    {
      title: 'Period From',
      dataIndex: 'periodFrom',
      key: 'periodFrom',
      width: 103,
      render: (date: number) => <div className="flex">{timeTranslate(date)}</div>,
      // sorter: true,
    },
    {
      title: 'Period To',
      dataIndex: 'periodTo',
      key: 'periodTo',
      width: 103,
      render: (date: number) => <div className="flex">{timeTranslate(date)}</div>,
      // sorter: true,
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (enabled: boolean) => <div className="flex">{String(enabled)}</div>,
      width: 103,
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      width: 146,
      render: (id: number) => (
        <div className="flex justify-between items-center">
          <div style={{ width: 77.5, cursor: 'pointer' }} className="flex justify-center">
            <Popconfirm
              title="Confirm to recalculate spent amount"
              onConfirm={() => tagClickEvent(id)}
              okText="Yes"
              cancelText="No"
            >
              <Tag color="blue">Recalculate</Tag>
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <div className="flex justify-end">
        <Button
          type="primary"
          onClick={() => {
            setIsCreateBudgetVisible(true);
          }}
        >
          New Budget
        </Button>
      </div>
      {!loading && kocBudgetData.data.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      ) : (
        <Table
          columns={columns}
          dataSource={kocBudgetData.data}
          loading={loading}
          pagination={{
            total: totalPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultPageSize: pageSize,
            defaultCurrent: 1,
            showSizeChanger: true,
            current: currentPage,
            onChange: (page: number, selectPageSize: number) => {
              setCurrentPage(page);
              setTotalPage(selectPageSize);
            },
            onShowSizeChange: (_current, size) => {
              setPageSize(size);
            },
          }}
        />
      )}
      <AffiliateCreateBudgetModal
        postType="NORMAL"
        isCreateBudgetVisible={isCreateBudgetVisible}
        setIsCreateBudgetVisible={setIsCreateBudgetVisible}
      />
    </div>
  );
};
export default KocBudgetPool;
