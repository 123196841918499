/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Alert, Button, Empty, Input, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';

import { getPostDetail, getReasons, getReportRecord, postReport } from '@/Redux/slices/ModalSlice';
import { getPost, setKeywordPost } from '@/Redux/slices/PostManagementSlice';

import Modal, { ModalProps } from '@/Modules/modal';
import ReasonCard from '@/Modules/reasonCard';
import TagLabel from '@/Modules/tagLabel';
import TimeTranslate from '@/Utils/timeTranslate';
import { getFrontEndDomainByEnv } from '@/Axios/index';
import fileText from '../../images/icon/fileText.svg';

const { Search } = Input;

const KeywordSearch = () => {
  const dispatch = useAppDispatch();
  const reasonArray = useAppSelector((state) => state.modal.value.reasons);
  const postArray = useAppSelector((state) => state.postManagement.value.keywordPost.data);
  const postPagination = useAppSelector(
    (state) => state.postManagement.value.keywordPost.pagination,
  );
  const postLoading = useAppSelector((state) => state.postManagement.loading);
  const postReportMessage = useAppSelector((state) => state.modal.message);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [form] = useForm();
  const hasForm = useRef(false);
  const hasSearch = useRef(false);
  const formOk = useRef(false);
  const [hasValue, setHasValue] = useState(false);
  const [postUuid, setPostUid] = useState('');
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [isPending, setIsPending] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>({});
  const [handleRecord, setHandleRecord] = useState<
    {
      key: string | number;
      handlingTime: string;
      admin: string;
      action: string;
      reason: string;
    }[]
  >([]);

  useEffect(() => {
    hasSearch.current = false;
    dispatch(
      setKeywordPost({
        data: {
          data: [],
          pagination: { page: 0, pageSize: 10, total: 0, hasNext: false },
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (isModalVisible && hasForm.current) {
      setHasValue(false);
      hasForm.current = false;
    }
  }, [form, isModalVisible]);

  useEffect(() => {
    if (postReportMessage === 'success' && formOk.current) {
      formOk.current = false;
      dispatch(
        getPost({
          pageName: 'keyword',
          params: {
            search_text: searchText,
            page_no: currentPage,
            page_size: currentPageSize,
          },
        }),
      );
    }
  }, [postReportMessage]);

  useEffect(() => {
    if (reasonArray.length > 0) {
      let title = '';
      const { reasonType } = reasonArray[0];
      switch (reasonType) {
        case 'DISABLE':
          title = 'Hide Reasons';
          break;
        case 'IGNORE':
          title = 'Ignore Reasons';
          break;
        case 'ENABLE':
          title = 'Release Reasons';
          break;
        default:
      }
      setModalProps({
        content: <ReasonCard form={form} setHasValue={setHasValue} checkBoxArray={reasonArray} />,
        title,
        onCancel: () => {
          form.resetFields();
          setIsModalVisible(false);
        },
        onOk: () => {
          form
            .validateFields()
            .then((values) => {
              const reasonIds = values.reason.filter((value: string | number) => value !== 'other');
              const otherReason = values.input;
              dispatch(
                postReport({
                  actionType: reasonType,
                  otherReason: otherReason ? otherReason.trim() : '',
                  postUuid,
                  reasonIds,
                }),
              );
              formOk.current = true;
              form.resetFields();
              setIsModalVisible(false);
            })
            .catch(() => {});
        },
      });
    }
  }, [reasonArray]);

  const tagClickEvent = useCallback((type: string, postId: string) => {
    setModalProps({});
    dispatch(getReasons({ reason_type: type }));
    hasForm.current = true;
    setPostUid(postId);
    setIsModalVisible(true);
  }, []);

  const columns = [
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      render: (categories: { categoryName: string }) => <p>{categories.categoryName}</p>,
      width: '9.86%',
    },
    {
      title: 'Post Title',
      dataIndex: 'postTitle',
      key: 'postTitle',
      render: (postTitle: { title: string; postId: string }) => (
        <div className="flex">
          <a  
            href= {`${getFrontEndDomainByEnv()}post/${postTitle.postId}`}
            target="_blank"
            rel="noreferrer" 
           className="h-6 line-clamp-1 break-all m-0">{postTitle.title}</a>
          <img
            aria-hidden
            src={fileText}
            alt=""
            className="cursor-pointer ml-2"
            onClick={() => {
              dispatch(getPostDetail(postTitle.postId));
              setModalProps({
                modalType: 'postDetail',
              });
              setIsModalVisible(true);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Post uuid',
      dataIndex: 'uuid',
      key: 'uuid',
      width: 100,
    },
    {
      title: 'Post Created',
      dataIndex: 'postCreated',
      key: 'postCreated',
      width: 117,
    },
    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      width: '9.95%',
      render: (text: string) => <p className="h-6 line-clamp-1 break-all m-0">{text}</p>,
    },
    {
      title: 'Pkey',
      dataIndex: 'pKey',
      key: 'pKey',
      width: '6.78%',
    },
    {
      title: 'Like',
      key: 'like',
      dataIndex: 'like',
      width: '5.11%',
    },
    {
      title: 'Comment',
      key: 'comment',
      dataIndex: 'comment',
      width: '8.27%',
    },
    {
      title: 'Report',
      key: 'report',
      dataIndex: 'report',
      width: '6.6%',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      className: 'pr-0',
      width: 146,
      render: (action: { postId: string; type: 'ENABLE' | 'DISABLE' | 'IGNORE' }) => (
        <div className="flex items-center justify-between">
          <div className="w-c-78 flex justify-center">
            <TagLabel
              labelType="status"
              statusType={action.type}
              cursorPointer
              onClick={() => tagClickEvent(action.type, action.postId)}
            />
          </div>
          <Button
            className="p-0"
            type="link"
            onClick={() => {
              dispatch(getReportRecord({ post_uuid: action.postId }));
              setModalProps({
                modalType: 'reportRecord',
                data: {
                  postUuid: action.postId,
                  onDetailClick: (reportId: number | string) => {
                    setIsPending(reportId === undefined);
                    setIsSecondModalVisible(true);
                  },
                  onRowClick: (record: {
                    key: string | number;
                    handlingTime: string;
                    admin: string;
                    action: string;
                    reason: {
                      text: string;
                    };
                  }) => {
                    setHandleRecord([
                      {
                        key: record.key,
                        handlingTime: record.handlingTime,
                        admin: record.admin,
                        action: record.action,
                        reason: record.reason.text,
                      },
                    ]);
                  },
                },
              });
              setIsModalVisible(true);
            }}
          >
            Record
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!postLoading && postArray && postArray.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [postArray, postLoading]);

  return (
    <>
      {hasSearch.current && !postLoading && (
        <div className="mb-6 mt-2">
          <Alert message={<>{postPagination.total} results found</>} type="warning" showIcon />
        </div>
      )}
      <div className="h-auto bg-white px-6 pb-2 pt-4">
        <Search
          placeholder="input search text"
          className="w-c-264"
          allowClear
          onSearch={(values) => {
            setSearchText(values.trim());
            setCurrentPage(0);
            setCurrentPageSize(10);
            if (values.trim() !== '') {
              hasSearch.current = true;
              dispatch(
                getPost({
                  pageName: 'keyword',
                  params: {
                    search_text: values.trim() || '',
                  },
                }),
              );
            } else {
              setCurrentPage(0);
              setCurrentPageSize(10);
              dispatch(
                setKeywordPost({
                  data: {
                    data: [],
                    pagination: { page: 0, pageSize: 10, total: 0, hasNext: false },
                  },
                }),
              );
            }
          }}
        />
        {!postLoading && postArray && postArray.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
        ) : (
          <Table
            className="mt-4"
            columns={columns}
            loading={loading}
            dataSource={postArray?.map((data) => ({
              key: data.uuid,
              uuid: data.uuid,
              categories: {
                categoryName: data.categories?.[0]?.categoryName || 'categories',
              },
              postTitle: { title: data.title || 'title', postId: data.uuid },
              postCreated: data.creationDate ? TimeTranslate(data.creationDate) : '',
              user: data.user ? data.user.name : 'user',
              pKey: data?.user?.hybrisId || 'pKey',
              like: data.statistics ? data.statistics.likeCount : 0,
              comment: data.statistics ? data.statistics.commentCount : 0,
              report: data.statistics ? data.statistics.reportCount : 0,
              action: {
                postId: data.uuid,
                type: data.disabled ? 'ENABLE' : 'DISABLE',
              },
            }))}
            pagination={{
              total: postPagination.total,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              defaultPageSize: currentPageSize,
              defaultCurrent: 1,
              current: currentPage + 1,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setCurrentPage(page - 1);
                setCurrentPageSize(pageSize);
                dispatch(
                  getPost({
                    pageName: 'keyword',
                    params: {
                      search_text: searchText,
                      page_no: page - 1,
                      page_size: pageSize,
                    },
                  }),
                );
              },
            }}
          />
        )}
      </div>
      <Modal
        okButtonProps={{
          disabled: !hasValue,
        }}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        {...modalProps}
      />
      <Modal
        visible={isSecondModalVisible}
        onCancel={() => setIsSecondModalVisible(false)}
        data={{ userRecordData: handleRecord, pending: isPending }}
        modalType="userRecord"
      />
    </>
  );
};

export default KeywordSearch;
