import React, { useEffect, useMemo, useRef } from 'react';
import './postDetailCard.scss';

import {
  HeartOutlined,
  MessageOutlined,
  ShopOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Avatar, Carousel, Spin, Statistic, Tag } from 'antd';

import { useAppSelector } from '@/Hooks/useAppRedux';

import TimeTranslate from '@/Utils/timeTranslate';

import { UserRoleEnums } from '@/Interfaces/I_userRole';
import PostVideoPlayer from '@/Components/PostVideoPlayer';
import ProductList from '../productList';
import TagLabel from '../tagLabel';
import UserRoleLabel from '../userRoleLabel';

const PostDetailCard = () => {
  const postDetailData = useAppSelector((state) => state.modal.value.postDetail);
  const loading = useAppSelector((state) => state.modal.loading);
  const mainRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (!loading) {
      mainRef.current?.scrollTo(0, 0);
    }
  }, [loading]);

  const shouldShowVideo = useMemo(() => {
    if (!postDetailData) {
      return false;
    }

    const { vod } = postDetailData;
    return Boolean(vod);
  }, [postDetailData]);

  return !loading && postDetailData ? (
    <div className="postDetailCard--root" ref={mainRef}>
      {/* postDetailCard__header */}
      <div className="postDetailCard__header">
        <div className="postDetailCard__header__user">
          <div className="flex items-center">
            <Avatar
              shape="square"
              size={32}
              src={postDetailData.user?.profileIconUrl}
              icon={<UserOutlined />} // 預設頭貼
            />
            <div className="postDetailCard__header__user__name">{postDetailData?.user?.name}</div>
            {postDetailData.user?.kocUserRole && postDetailData.user?.kocUserRole !== 'CUSTOMER' ? (
              <UserRoleLabel userRoleStr={postDetailData.user?.kocUserRole as UserRoleEnums} />
            ) : (
              <TagLabel
                labelType="level"
                levelType={
                  postDetailData.user?.membershipLevel.toLowerCase() as 'normal' | 'vip' | 'goldvip'
                }
              />
            )}
          </div>
          <div className="flex items-center">
            {postDetailData.user.store ? (
              <>
                <ShopOutlined />
                <Tag>{postDetailData.user.store?.platform}</Tag>
                <div className="font-bold">&nbsp;Store Code&nbsp;</div>
                <Tag>{postDetailData.user.store?.code}</Tag>
              </>
            ) : null}
          </div>
        </div>
        <div className="postDetailCard__header__postData">
          <div className="postDetailCard__header__postData__statistic">
            <Statistic
              className="statistic"
              title="Like"
              value={postDetailData.statistics?.likeCount}
              prefix={<HeartOutlined />}
            />
            <Statistic
              className="statistic"
              title="Comment"
              value={postDetailData.statistics?.commentCount}
              prefix={<MessageOutlined />}
            />
            <Statistic
              className="statistic"
              title="Reported"
              value={postDetailData.statistics?.reportCount}
              prefix={<WarningOutlined />}
            />
          </div>
          <div className="postDetailCard__header__postData__other">
            <p>Street: {postDetailData?.categories[0].categoryName}</p>
            <p>Attachment Type: {postDetailData?.attachmentType}</p>
            <p>Last Updates Time: {TimeTranslate(postDetailData?.lastModifiedDate, true)}</p>
            <p>Post Created Time: {TimeTranslate(postDetailData?.creationDate, true)}</p>
          </div>
        </div>
      </div>
      {/* postDetailCard__content */}
      <div className="postDetailCard__content">
        <div className="postDetailCard__content__left">
          {shouldShowVideo ? (
            <div className="postDetail__video">
              <PostVideoPlayer
                src={postDetailData.vod?.videoSmilUrl}
                poster={postDetailData.vod?.thumbnailId}
              />
            </div>
          ) : (
            <div className="postDetail__image">
              <Carousel className="postDetail__image__bg">
                {postDetailData?.medias?.map((e) => (
                  <img src={e.url} alt={e.imageId} key={e.imageId} />
                ))}
              </Carousel>
            </div>
          )}
          <div className="postDetail__productList">
            <div className="postDetail__productList__title">Products</div>
            {postDetailData?.skus?.length > 0 && (
              <ProductList productArray={postDetailData?.skus} />
            )}
            {postDetailData?.littleMallSkus?.length > 0 && (
              <ProductList littleMallProductArray={postDetailData?.littleMallSkus} />
            )}
          </div>
        </div>
        <div className="postDetailCard__content__right">
          <div className="postDetail__postTitle">{postDetailData?.title}</div>
          <div className="postDetail__postContent">{postDetailData?.content}</div>
          <div className="postDetail__postTag">
            {postDetailData?.tags?.length > 0 &&
              postDetailData?.tags?.map((e, index) => <Tag key={index?.toString()}>{e}</Tag>)}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="postDetailCard--loading">
      <Spin size="large" />
    </div>
  );
};
export default PostDetailCard;
