import { getFrontEndDomainByEnv } from '@/Axios/index';
import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import {
  Button,
  DatePicker,
  Empty,
  InputNumber,
  Popconfirm,
  Select,
  SelectProps,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import Search from 'antd/lib/input/Search';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  getCategories,
  getPost,
  patchPostCategory,
  patchPostData,
} from '@/Redux/slices/PostManagementSlice';
import timeTranslate from '@/Utils/timeTranslate';
import { SorterResult } from 'antd/lib/table/interface';
import {
  CategoriesData,
  PostManagementData,
  UpdatePostDataParam,
} from '@/Interfaces/I_postManagement';
import TagLabel from '@/Modules/tagLabel';
import Modal, { ModalProps } from '@/Modules/modal';
import { getPostDetail, getReasons, getReportRecord, postReport } from '@/Redux/slices/ModalSlice';
import { useForm } from 'antd/lib/form/Form';
import ReasonCard from '@/Modules/reasonCard';
import fileText from '../../images/icon/fileText.svg';

type DateRange = [Moment | null, Moment | null] | null;
interface SorterDataType {
  score: number;
  date: number;
}
interface ExportCsvData {
  PostTitle: string;
  PostCreated: string;
  User: string;
  Score: number;
  Action: boolean;
}

const { RangePicker } = DatePicker;

const VALID_MONTH_RANGE = 6;
const DEFAULT_DATES = [moment().subtract(30, 'days'), moment()];
const ATTACHMENT_TYPE_OPTIONS = [
  { value: 'LONG_VIDEOS', label: '長片' },
  { value: 'VIDEOS', label: '短片' },
  { value: 'IMAGES', label: '圖片' },
];
const SEARCH_OPTIONS = [
  { value: 'post', label: 'Post Title' },
  { value: 'user', label: 'User' },
];

const PrpPost = ({ tabActive }: { tabActive: boolean }) => {
  const dispatch = useAppDispatch();
  const categoryData = useAppSelector((state) => state.postManagement.value.categories);
  const postData = useAppSelector((state) => state.postManagement.value.post);
  const loading = useAppSelector((state) => state.postManagement.loading);
  const reasonArray = useAppSelector((state) => state.modal.value.reasons);
  const userName = useAppSelector((state) => state.account.user.username);

  const [categoryOptions, setCategoryOptions] = useState<SelectProps[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [postId, setPostId] = useState('');
  const [searchOption, setSearchOption] = useState('post');
  const [selectRowKeys, setSelectRowKeys] = useState([] as Array<React.Key>);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [selectRow, setSelectRow] = useState([] as ExportCsvData[]);
  const [modalProps, setModalProps] = useState<ModalProps>({});
  const [hasValue, setHasValue] = useState(false);
  const [handleRecord, setHandleRecord] = useState<
    {
      key: string | number;
      handlingTime: string;
      admin: string;
      action: string;
      reason: string;
    }[]
  >([]);
  const [pageSize, setPageSize] = useState(10);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterDataType>>({
    order: 'descend',
    columnKey: 'score',
  });
  const [dates, setDates] = useState<DateRange>(DEFAULT_DATES as DateRange);
  const [form] = useForm();
  const selectCategory = useRef<string>('');
  const selectAttactmentType = useRef<string>('');
  const updatePostData = useRef<UpdatePostDataParam[]>([]);
  const hasForm = useRef(false);

  const totalPage = useMemo(
    () => (!loading && postData.data ? postData.pagination.total : 10),
    [loading, postData],
  );

  const data = useMemo(() => {
    const pageIndex = postData.pagination.page * pageSize;

    return postData.data.map((item: PostManagementData, index: number) => ({
      key: item.uuid,
      no: index + 1 + pageIndex,
      categories: {
        categoryId: item.categories[0].categoryId,
        categoryName: item.categories[0].categoryName,
        postUuid: item.uuid,
      },
      postTitle: {
        title: item.title,
        uuid: item.uuid,
      },
      uuid: item.uuid,
      date: item.creationDate,
      user: item.user.name,
      pKey: item.user.hybrisId,
      score: {
        popularityScore: item.popularityScore,
        extraScore: item.extraScore,
      },
      pdp: {
        approved: item.pdp,
        postUuid: item.uuid,
      },
      scoreCalculate: {
        disabled: item.disabled,
        extraScore: item.extraScore,
        postUuid: item.uuid,
      },
      action: { disabled: item.disabled, postUuid: item.uuid },
      landingPinned: {
        postUuid: item.uuid,
        landingPinned: item.landingPinned,
        disabled: item.attachmentType === 'LONG_VIDEOS',
      },
      explorePinned: {
        explorePinned: item.explorePinned,
        postUuid: item.uuid,
        disabled: item.disabled,
      },
      approval: {
        disabled: item.disabled,
        postUuid: item.uuid,
      },
    }));
  }, [pageSize, postData.data, postData.pagination.page]);

  const pageName = 'Category';
  const searchOptions = [
    {
      value: 'post',
      label: 'Post Title',
    },
    {
      value: 'user',
      label: 'User',
    },
  ];

  const outOfRange = (from: Moment | null, to: Moment | null) =>
    !!from && !!to && to.diff(from, 'months') >= VALID_MONTH_RANGE;

  const disabledDate = (current: Moment) =>
    !!dates && (outOfRange(dates[0], current) || outOfRange(current, dates[1]));

  const searchPosts = () => {
    if (dates && dates[0] && dates[1]) {
      const sort =
        sortedInfo.column !== undefined
          ? (sortedInfo.columnKey as string)
              .replace(/[A-Z]/g, (letter) => `_${letter}`)
              .toUpperCase()
              .concat(sortedInfo.order === 'ascend' ? '_ASC' : '_DESC')
          : 'SCORE_DESC';
      dispatch(
        getPost({
          pageName,
          params: {
            title: searchOption === 'post' ? searchValue : null,
            user_name: searchOption !== 'post' ? searchValue : null,
            category:
              selectCategory.current ||
              categoryData.map((category) => category.categoryId).join(','),
            attachment_type: selectAttactmentType.current,
            sort,
            page_no: 0,
            page_size: pageSize,
            date_from: dates[0].format('YYYY-MM-DD'),
            date_to: dates[1].format('YYYY-MM-DD'),
            source: 'PRP',
          },
        }),
      );
    }
  };

  // dispatch get categories
  useEffect(() => {
    if (userName) {
      dispatch(getCategories());
    }
  }, [dispatch, userName]);

  useEffect(() => {
    if (userName && categoryData.length > 0 && dates && dates[0] && dates[1] && tabActive) {
      const sort =
        sortedInfo.column !== undefined
          ? (sortedInfo.columnKey as string)
              .replace(/[A-Z]/g, (letter) => `_${letter}`)
              .toUpperCase()
              .concat(sortedInfo.order === 'ascend' ? '_ASC' : '_DESC')
          : 'SCORE_DESC';
      dispatch(
        getPost({
          pageName,
          params: {
            title: searchOption === 'post' ? searchValue : null,
            user_name: searchOption !== 'post' ? searchValue : null,
            category:
              selectCategory.current ||
              categoryData.map((category) => category.categoryId).join(','),
            attachment_type: selectAttactmentType.current,
            sort,
            page_no: currentPage - 1,
            page_size: pageSize,
            date_from: dates[0].format('YYYY-MM-DD'),
            date_to: dates[1].format('YYYY-MM-DD'),
            source: 'PRP',
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    categoryData,
    currentPage,
    pageSize,
    sortedInfo.column,
    sortedInfo.columnKey,
    sortedInfo.field,
    sortedInfo.order,
    tabActive,
  ]);

  useEffect(() => {
    if (categoryData && categoryData.length > 0) {
      const newMapData = categoryData.map((item: CategoriesData) => ({
        value: item.categoryId,
        label: item.name,
      }));
      setCategoryOptions(newMapData);
    }
  }, [categoryData]);

  useEffect(() => {
    if (reasonArray.length > 0) {
      let title = '';
      const { reasonType } = reasonArray[0];
      switch (reasonType) {
        case 'DISABLE':
          title = 'Hide Reasons';
          break;
        case 'IGNORE':
          title = 'Ignore Reasons';
          break;
        case 'ENABLE':
          title = 'Release Reasons';
          break;
        default:
      }
      setModalProps({
        content: <ReasonCard form={form} setHasValue={setHasValue} checkBoxArray={reasonArray} />,
        title,
        onCancel: () => {
          form.resetFields();
          setIsModalVisible(false);
        },
        onOk: () => {
          form
            .validateFields()
            .then((values) => {
              const reasonIds = values.reason.filter((value: string | number) => value !== 'other');
              const otherReason = values.input;

              dispatch(
                postReport({
                  actionType: reasonType,
                  otherReason: otherReason ? otherReason.trim() : '',
                  postUuid: postId,
                  reasonIds,
                }),
              );
              setTimeout(() => {
                const sort =
                  sortedInfo.column !== undefined
                    ? (sortedInfo.field as string)
                        .replace(/[A-Z]/g, (letter) => `_${letter}`)
                        .toUpperCase()
                        .concat(sortedInfo.order === 'ascend' ? '_ASC' : '_DESC')
                    : 'SCORE_DESC';
                dispatch(
                  getPost({
                    pageName,
                    params: {
                      title: searchOption === 'post' ? searchValue : null,
                      user_name: searchOption !== 'post' ? searchValue : null,
                      category:
                        selectCategory.current ||
                        categoryData.map((category) => category.categoryId).join(','),
                      attachment_type: selectAttactmentType.current,
                      date_from: ((dates && dates[0]) || DEFAULT_DATES[0]).format('YYYY-MM-DD'),
                      date_to: ((dates && dates[1]) || DEFAULT_DATES[0]).format('YYYY-MM-DD'),
                      sort,
                      page_no: currentPage - 1,
                      page_size: pageSize,
                      source: 'PRP',
                    },
                  }),
                );
              }, 1000);

              form.resetFields();
              setIsModalVisible(false);
            })
            .catch(() => {});
        },
      });
    }
  }, [
    categoryData,
    currentPage,
    dates,
    dispatch,
    form,
    pageSize,
    postId,
    reasonArray,
    searchOption,
    searchValue,
    selectCategory,
    sortedInfo.column,
    sortedInfo.field,
    sortedInfo.order,
  ]);

  // clear default form data
  useEffect(() => {
    if (isModalVisible && hasForm.current) {
      form.resetFields();
      setHasValue(false);
      hasForm.current = false;
    }
  }, [form, isModalVisible]);

  const tagClickEvent = useCallback(
    (type: string, postClickId: string) => {
      setModalProps({});
      dispatch(getReasons({ reason_type: type }));
      hasForm.current = true;
      setPostId(postClickId);
      setIsModalVisible(true);
    },
    [dispatch],
  );

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
    },
    {
      title: 'Post Title',
      dataIndex: 'postTitle',
      key: 'postTitle',
      className: 'pr-0',
      render: (item: { title: string; uuid: string }) => {
        const { title, uuid } = item;
        return (
          <Tooltip placement="leftTop" title={title}>
            <div className="flex items-center">
              <a
                href={`${getFrontEndDomainByEnv()}post/${uuid}`}
                target="_blank"
                rel="noreferrer"
                className="line-clamp-3 break-all mb-0"
              >
                {title}
              </a>
              <img
                aria-hidden
                src={fileText}
                alt="photo"
                className="cursor-pointer ml-2"
                onClick={() => {
                  setModalProps({
                    modalType: 'postDetail',
                  });
                  setIsModalVisible(true);
                  dispatch(getPostDetail(uuid));
                }}
              />
            </div>
          </Tooltip>
        );
      },
      width: '15%',
    },
    {
      title: 'Post uuid',
      dataIndex: 'uuid',
      key: 'uuid',
      width: '8%',
      ellipsis: {
        showTitle: false,
      },
      render: (uuid: string) => (
        <Tooltip placement="leftTop" title={uuid}>
          {uuid}
        </Tooltip>
      ),
    },
    {
      title: 'KOC Category',
      dataIndex: 'categories',
      key: 'categories',
      width: '10%',
      render: (categories: { categoryId: string; categoryName: string; postUuid: string }) => {
        const { categoryId, postUuid } = categories;
        const optionIdx = categoryOptions.findIndex((option) => option.value === categoryId);
        return (
          <Select
            className="w-full"
            options={categoryOptions.filter((option) => option.value !== categoryId)}
            defaultValue={categoryOptions[optionIdx]}
            onChange={(value) => {
              dispatch(
                patchPostCategory({
                  postUuid,
                  categoryId: value,
                }),
              );
            }}
          />
        );
      },
    },
    {
      title: 'Post Created',
      dataIndex: 'date',
      key: 'date',
      width: '10%',
      render: (date: number) => <div className="flex">{timeTranslate(date)}</div>,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: '5%',
      render: (text: string) => (
        <Tooltip placement="leftTop" title={text}>
          <p className="h-6 line-clamp-1 break-all m-0">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: 'Pkey',
      dataIndex: 'pKey',
      key: 'pKey',
      width: '5%',
    },
    {
      title: 'Approval',
      dataIndex: 'approval',
      width: '8%',
      render: ({ disabled, postUuid }: { disabled: boolean; postUuid: string }) => (
        <Switch
          defaultChecked={!disabled}
          onChange={(val) => {
            dispatch(patchPostData([{ postUuid, disabled: !val }]));
          }}
        />
      ),
    },
    {
      title: 'Top 20 Landing',
      key: 'landingPinned',
      dataIndex: 'landingPinned',
      width: '8%',
      render: (item: { postUuid: string; landingPinned: boolean; disabled: boolean }) => {
        const { postUuid, landingPinned, disabled } = item;
        return (
          <Popconfirm
            title="Confirm?"
            onConfirm={() => dispatch(patchPostData([{ postUuid, landingPinned: !landingPinned }]))}
            okText="Yes"
            cancelText="No"
            disabled={disabled}
          >
            <TagLabel
              labelType="status"
              statusType={disabled ? 'IGNORE' : landingPinned ? 'ENABLE' : 'DISABLE'}
              showDescription={false}
            />
          </Popconfirm>
        );
      },
    },
    {
      title: 'ShareHub Pin Post',
      key: 'explorePinned',
      dataIndex: 'explorePinned',
      width: '7%',
      render: (item: { explorePinned: boolean; postUuid: string; disabled: boolean }) => {
        const { explorePinned, postUuid, disabled } = item;
        return (
          <Popconfirm
            title="Confirm?"
            onConfirm={() => dispatch(patchPostData([{ postUuid, explorePinned: !explorePinned }]))}
            okText="Yes"
            cancelText="No"
            disabled={disabled}
          >
            <TagLabel
              labelType="status"
              statusType={disabled ? 'IGNORE' : explorePinned ? 'ENABLE' : 'DISABLE'}
              showDescription={false}
            />
          </Popconfirm>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: '7%',
      render: (score: { popularityScore: number; extraScore: number }) => {
        const { popularityScore, extraScore } = score;
        return (
          <p className={`mb-0 ${extraScore !== 0 ? 'text-text-scoreRed' : ''}`}>
            {popularityScore}
          </p>
        );
      },
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'score' ? sortedInfo.order : null,
    },
    {
      title: 'Score (+/-)',
      dataIndex: 'scoreCalculate',
      key: 'scoreCalculate',
      width: '10%',
      render: (item: { disabled: boolean; extraScore: number; postUuid: string }) => {
        const { extraScore, disabled, postUuid } = item;
        return (
          <InputNumber
            defaultValue={extraScore}
            controls={false}
            bordered
            disabled={disabled}
            onChange={(updateScore) => {
              const patchExtraScoreDataIdx = updatePostData.current?.findIndex(
                (ele) => ele.postUuid === postUuid,
              );
              if (patchExtraScoreDataIdx > -1) {
                updatePostData.current[patchExtraScoreDataIdx] = {
                  postUuid,
                  extraScore: updateScore || 0,
                };
              } else {
                updatePostData.current.push({ postUuid, extraScore: updateScore || 0 });
              }
            }}
            onPressEnter={(e: any) => {
              const Regex = /^[-]?[1-9]?[0-9]+$/g;
              if (!Regex.test(e.target.value)) return;
              dispatch(patchPostData(updatePostData.current));
              updatePostData.current = [];
            }}
          />
        );
      },
    },
    {
      title: 'PDP',
      key: 'pdp',
      dataIndex: 'pdp',
      width: '5%',
      render: (item: { approved: boolean; postUuid: string }) => {
        const { approved, postUuid } = item;
        return (
          <Popconfirm
            title="Confirm?"
            onConfirm={() => dispatch(patchPostData([{ postUuid, pdp: !approved }]))}
            okText="Yes"
            cancelText="No"
          >
            <TagLabel
              labelType="status"
              statusType={approved ? 'ENABLE' : 'DISABLE'}
              showDescription={false}
            />
          </Popconfirm>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: '10%',
      className: 'pr-0',
      render: (item: { disabled: boolean; postUuid: string }) => {
        const { disabled, postUuid } = item;
        const type = disabled === true ? 'ENABLE' : 'DISABLE';
        return (
          <div className="flex justify-between items-center">
            <div style={{ width: 77.5 }} className="flex justify-center">
              <TagLabel
                labelType="status"
                statusType={type}
                cursorPointer
                onClick={() => tagClickEvent(type, postUuid)}
              />
            </div>
            <Button
              type="link"
              className="pr-0"
              onClick={() => {
                dispatch(getReportRecord({ post_uuid: postUuid }));
                setModalProps({
                  data: {
                    postUuid,
                    onDetailClick: (reportId: string | number) => {
                      // fetch user report api
                      setIsPending(reportId === undefined);
                      setIsSecondModalVisible(true);
                    },
                    onRowClick: (record: {
                      key: string | number;
                      handlingTime: string;
                      admin: string;
                      action: string;
                      reason: {
                        text: string;
                      };
                    }) => {
                      // get table row clicked -> user report first table
                      setHandleRecord([
                        {
                          key: record.key,
                          handlingTime: record.handlingTime,
                          admin: record.admin,
                          action: record.action,
                          reason: record.reason.text,
                        },
                      ]);
                    },
                  },
                  modalType: 'reportRecord',
                });
                setIsModalVisible(true);
              }}
            >
              Record
            </Button>
          </div>
        );
      },
    },
  ];

  const exportCsv = useCallback((selectedRows: typeof data) => {
    const filterData = selectedRows
      .filter((item: (typeof data)[0]) => item !== undefined)
      .map((item: (typeof data)[0]) => ({
        PostTitle: item.postTitle.title,
        PostCreated: `${timeTranslate(item.date)}`,
        User: item.user,
        Score: item.score.popularityScore,
        Action: item.action.disabled,
      }));
    setSelectRow(filterData);
  }, []);
  return (
    <>
      <div className="h-auto bg-white p-6 pb-2">
        <div className="flex items-center pb-4 justify-end">
          <div className="pr-4">
            <RangePicker
              inputReadOnly
              disabledDate={disabledDate}
              defaultValue={DEFAULT_DATES as DateRange}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              disabled={loading}
            />
          </div>
          <Button disabled={loading || !dates || !dates[0] || !dates[1]} onClick={searchPosts}>
            Search
          </Button>
        </div>
        <div className="flex items-center pb-4 justify-between">
          <div className="flex">
            {categoryOptions.length > 0 && (
              <Select
                mode="multiple"
                style={{ width: 600 }}
                options={categoryOptions}
                onChange={(value) => {
                  selectCategory.current = value.join(',');
                }}
              />
            )}
          </div>
          <div className="flex">
            <Select
              mode="multiple"
              style={{ width: 300 }}
              options={ATTACHMENT_TYPE_OPTIONS}
              onChange={(value) => {
                selectAttactmentType.current = value.join(',');
              }}
            />
          </div>
          <div className="flex items-center">
            <div className="pr-4">
              <Select
                style={{ width: 110 }}
                defaultValue={searchOption}
                options={searchOptions}
                onChange={(value) => {
                  setSearchOption(value);
                }}
                bordered={false}
              />
            </div>
            <Search
              placeholder="input text"
              allowClear
              onSearch={(value) => {
                setSearchValue(value.trim());
                setCurrentPage(1);
              }}
              style={{ width: 264 }}
              className="pr-4"
              disabled={loading}
            />
            <Button disabled={selectRowKeys.length === 0 && loading}>
              <CSVLink uFEFF filename="download.csv" data={selectRow} target="_blank">
                Generate CSV
              </CSVLink>
            </Button>
          </div>
        </div>
        {!loading && data.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              total: totalPage,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              defaultPageSize: pageSize,
              defaultCurrent: 1,
              showSizeChanger: true,
              current: currentPage,
              onChange: (page: number) => {
                setCurrentPage(page);
              },
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
            }}
            rowSelection={{
              preserveSelectedRowKeys: true,
              selectedRowKeys: selectRowKeys,
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectRowKeys(selectedRowKeys);
                exportCsv(selectedRows as typeof data);
              },
            }}
            onChange={(pagination, filters, sorter) => {
              const sorterInfo = sorter as SorterResult<SorterDataType>;
              setSortedInfo(sorterInfo);
            }}
          />
        )}
      </div>
      <Modal
        okButtonProps={{
          disabled: !hasValue,
        }}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        {...modalProps}
      />
      <Modal
        visible={isSecondModalVisible}
        onCancel={() => setIsSecondModalVisible(false)}
        data={{ userRecordData: handleRecord, pending: isPending }}
        modalType="userRecord"
      />
    </>
  );
};

export default PrpPost;
