import React, { useEffect, useState } from 'react';

import { PlusOutlined, PushpinOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Space,
  Table,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { CampaignUiData } from '@/Interfaces/I_Campaign';

import { getCampaignData, patchCampaignData, postCampaignData } from '@/Redux/slices/CampaignSlice';

import { uploadKocCampaignPhotoUrl } from '@/Axios/api/Media';
import { customAxios } from '@/Axios/index';

import TagLabel from '@/Modules/tagLabel';

import timeTranslate from '@/Utils/timeTranslate';

import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

const { Column } = Table;

enum EventButtonTypeOptions {
  MALL_DOLLAR = 'Mall Dollar',
  GIFT = 'Gift',
  WINNER = 'Winner',
  NONE = 'None',
}

const Campaign = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { campaignData } = useAppSelector((state) => state.campaign.value);
  const loading = useAppSelector((state) => state.campaign.loading);
  const [editingKey, setEditingKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [campaignUiData, setCampaignUiData] = useState<CampaignUiData[]>([]);
  const imageId = Form.useWatch('imageId', form);

  const isEditing = (record: CampaignUiData) => record.key === editingKey;

  const bearerToken = customAxios.defaults.headers.common.Authorization;
  const uploadPhotoUrl = customAxios.defaults.baseURL + uploadKocCampaignPhotoUrl;

  const handleAddCampaign = () => {
    const newCampaign: CampaignUiData = {
      key: '',
      uuid: '',
      postUuid: '',
      imageId: '',
      imageUrl: '',
      backgroundColor: '#000000',
      titleColor: '#000000',
      description: '',
      descriptionColor: '#ffffff',
      eventButtonType: 'MALL_DOLLAR',
      eventButtonText: '',
      eventButtonBackgroundColor: '#000000',
      disabled: false,
      pinned: false,
      startTime: moment().startOf('day').unix(),
      endTime: moment().startOf('day').unix(),
    };
    // empty the image fileList
    setFileList([]);
    setCampaignUiData([newCampaign, ...campaignUiData]);
    form.setFieldsValue({
      ...newCampaign,
      startTime: dayjs().startOf('day'),
      endTime: dayjs().startOf('day'),
    });
    setEditingKey('');
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || file.response[0].imageUrl);
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    let onlyKeepOneImg = [...info.fileList];
    onlyKeepOneImg = onlyKeepOneImg.slice(-1);
    setFileList(onlyKeepOneImg);
    if (info.file.status === 'done') {
      form.setFieldsValue({
        imageUrl: info.file.response[0].imageUrl,
        imageId: info.file.response[0].imageId,
      });
    }
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as CampaignUiData;

      const newData = [...campaignData.data];
      const index = newData.findIndex((item) => key === item.uuid);
      const newCampaign = {
        ...row,
        uuid: key,
        startTime: moment(row.startTime).format('X'),
        endTime: moment(row.endTime).format('X'),
      };
      if (index === -1) {
        dispatch(postCampaignData(newCampaign));
      } else {
        dispatch(patchCampaignData(newCampaign));
      }
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const edit = (record: Partial<CampaignUiData> & { key: React.Key }) => {
    setFileList([
      {
        uid: '-1',
        name: 'originalImage',
        status: 'done',
        url: record.imageUrl,
      },
    ]);
    form.setFieldsValue({
      ...record,
      startTime: dayjs.unix(record.startTime || 0),
      endTime: dayjs.unix(record.endTime || 0),
    });
    setEditingKey(record.key);
  };

  const handleModalCancel = () => setPreviewOpen(false);
  const cancel = () => {
    if (editingKey === '') {
      setCampaignUiData((prev) => {
        const data = [...prev].filter((d) => d.key !== editingKey);
        return data;
      });
    } else {
      setEditingKey('');
      setFileList([]);
    }
  };

  useEffect(() => {
    dispatch(
      getCampaignData({
        page_no: currentPage - 1,
        page_size: pageSize,
      }),
    );
  }, [currentPage, dispatch, pageSize]);

  useEffect(() => {
    setCampaignUiData(
      campaignData.data.map((campaign) => ({
        ...campaign,
        key: campaign.uuid,
      })),
    );
    setTotalPage(campaignData.pagination.total);
  }, [campaignData.data, campaignData.pagination.total]);

  return (
    <div className="h-auto bg-white p-6 pb-2">
      <div className="flex justify-end pb-2">
        <Button
          className="mb-1"
          onClick={handleAddCampaign}
          type="primary"
          disabled={editingKey !== '' || campaignUiData.some((d) => d.key === '')}
        >
          Add Campaign
        </Button>
      </div>
      {!loading && campaignUiData?.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      ) : (
        <>
          <Form form={form} component={false}>
            <Table
              dataSource={campaignUiData}
              loading={loading}
              pagination={{
                total: totalPage,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                defaultPageSize: pageSize,
                defaultCurrent: 1,
                showSizeChanger: true,
                current: currentPage,
                onChange: (page: number, selectPageSize: number) => {
                  setCurrentPage(page);
                  setTotalPage(selectPageSize);
                },
                onShowSizeChange: (_current, size) => {
                  setPageSize(size);
                },
              }}
            >
              <Column title="UUID" dataIndex="uuid" key="uuid" ellipsis />
              <Column
                title="Post UUID"
                dataIndex="postUuid"
                key="postUuid"
                width="10%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item name="postUuid" help="No post uuid will be non-clickable">
                      <Input defaultValue={record.postUuid} />
                    </Form.Item>
                  ) : (
                    record.postUuid
                  )
                }
              />
              <Column
                title="Image URL"
                dataIndex="imageUrl"
                key="imageUrl"
                ellipsis
                width="7%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <>
                      <Form.Item
                        name="imageUrl"
                        getValueFromEvent={(e) => {
                          if (Array.isArray(e)) {
                            return e;
                          }
                          return e?.fileList;
                        }}
                        rules={[
                          {
                            // required: true,
                            whitespace: true,
                            message: 'Missing image.',
                          },
                        ]}
                      >
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          headers={{
                            authorization: bearerToken,
                          }}
                          action={uploadPhotoUrl}
                          name="images"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onRemove={() => {
                            form.setFieldsValue({ imageUrl: '' });
                          }}
                          onChange={handleChange}
                        >
                          {fileList.length < 1 && (
                            <>
                              <PlusOutlined />
                              <div className="mt-4">Upload</div>
                            </>
                          )}
                        </Upload>
                      </Form.Item>
                      <Form.Item name="imageId">{imageId ?? 'Original Image'}</Form.Item>
                    </>
                  ) : (
                    <Typography.Link href={record.imageUrl} target="_black">
                      {record.imageUrl.slice(-28).trim()}
                    </Typography.Link>
                  )
                }
              />
              <Column
                title="Background Color"
                dataIndex="backgroundColor"
                key="backgroundColor"
                width="5%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item
                      name="backgroundColor"
                      rules={[
                        {
                          required: true,
                          message: 'Missing Description Color.',
                        },
                      ]}
                    >
                      <Input type="color" defaultValue={record.backgroundColor} />
                    </Form.Item>
                  ) : (
                    <Input type="color" value={record.backgroundColor} disabled />
                  )
                }
              />
              <Column
                title="Title Color"
                dataIndex="titleColor"
                key="titleColor"
                width="5%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item
                      name="titleColor"
                      rules={[
                        {
                          required: true,
                          message: 'Missing Title Color.',
                        },
                      ]}
                    >
                      <Input type="color" defaultValue={record.titleColor} />
                    </Form.Item>
                  ) : (
                    <Input type="color" value={record.titleColor} disabled />
                  )
                }
              />
              <Column
                title="Description"
                dataIndex="description"
                key="description"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: 'Missing description.',
                        },
                      ]}
                    >
                      <Input defaultValue={record.description} />
                    </Form.Item>
                  ) : (
                    record.description
                  )
                }
              />
              <Column
                title="Description Color"
                dataIndex="descriptionColor"
                key="descriptionColor"
                width="5%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item
                      name="descriptionColor"
                      rules={[
                        {
                          required: true,
                          message: 'Missing Description Color.',
                        },
                      ]}
                    >
                      <Input type="color" defaultValue={record.descriptionColor} />
                    </Form.Item>
                  ) : (
                    <Input type="color" value={record.descriptionColor} disabled />
                  )
                }
              />
              <Column
                title="Event Button Type"
                dataIndex="eventButtonType"
                key="eventButtonType"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item
                      name="eventButtonType"
                      rules={[
                        {
                          required: true,
                          message: 'Missing Description Color.',
                        },
                      ]}
                    >
                      <Radio.Group value={record.eventButtonType}>
                        <Space direction="vertical">
                          {Object.keys(EventButtonTypeOptions).map((eventButtonTypeOption) => (
                            <Radio value={eventButtonTypeOption}>
                              {
                                EventButtonTypeOptions[
                                  eventButtonTypeOption as keyof typeof EventButtonTypeOptions
                                ]
                              }
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  ) : (
                    EventButtonTypeOptions[
                      record.eventButtonType as keyof typeof EventButtonTypeOptions
                    ]
                  )
                }
              />
              <Column
                title="Event Button Text"
                dataIndex="eventButtonText"
                key="eventButtonText"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item name="eventButtonText">
                      <Input defaultValue={record.eventButtonText} />
                    </Form.Item>
                  ) : (
                    record.eventButtonText
                  )
                }
              />
              <Column
                title="Event Btn Bg Color"
                dataIndex="eventButtonBackgroundColor"
                key="eventButtonBackgroundColor"
                width="5%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item
                      name="eventButtonBackgroundColor"
                      rules={[
                        {
                          required: true,
                          message: 'Missing Event Button Background Color.',
                        },
                      ]}
                    >
                      <Input type="color" defaultValue={record.eventButtonBackgroundColor} />
                    </Form.Item>
                  ) : (
                    <Input type="color" value={record.eventButtonBackgroundColor} disabled />
                  )
                }
              />
              <Column
                title="Start Time"
                dataIndex="startTime"
                key="startTime"
                width="10%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item name="startTime">
                      <DatePicker
                        defaultValue={moment.unix(record.startTime)}
                        format="YYYY/MM/DD HH:mm"
                        showTime
                      />
                    </Form.Item>
                  ) : (
                    timeTranslate(record.startTime, true)
                  )
                }
              />
              <Column
                title="End Time"
                dataIndex="endTime"
                key="endTime"
                width="10%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item name="endTime">
                      <DatePicker
                        defaultValue={moment.unix(record.endTime)}
                        format="YYYY/MM/DD HH:mm"
                        showTime
                      />
                    </Form.Item>
                  ) : (
                    timeTranslate(record.endTime, true)
                  )
                }
              />
              <Column
                title="Active"
                dataIndex="disabled"
                key="disabled"
                width="5%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item name="disabled">
                      <Radio.Group value={record.disabled}>
                        <Radio value={false}>Yes</Radio>
                        <Radio value>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : record.disabled ? (
                    <TagLabel labelType="status" statusType="DISABLE" showDescription={false} />
                  ) : (
                    <TagLabel labelType="status" statusType="ENABLE" showDescription={false} />
                  )
                }
              />
              <Column
                title="Pinned"
                dataIndex="pinned"
                key="pinned"
                width="5%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <Form.Item name="pinned">
                      <Radio.Group value={record.pinned}>
                        <Radio value>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : record.pinned ? (
                    <TagLabel
                      icon={<PushpinOutlined />}
                      statusType="ENABLE"
                      showDescription={false}
                    />
                  ) : (
                    <TagLabel
                      icon={<PushpinOutlined />}
                      statusType="IGNORE"
                      showDescription={false}
                    />
                  )
                }
              />
              <Column
                title="Operation"
                dataIndex="operation"
                width="7%"
                render={(_: unknown, record: CampaignUiData) =>
                  isEditing(record) ? (
                    <span>
                      <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                        Save
                      </Typography.Link>
                      <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <div>Cancel</div>
                      </Popconfirm>
                    </span>
                  ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                      Edit
                    </Typography.Link>
                  )
                }
              />
            </Table>
          </Form>
          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleModalCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default Campaign;
