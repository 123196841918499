/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { Button, DatePicker, Empty, Input, Popconfirm, Select, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '@/Hooks/useAppRedux';
import { PostManagementData, PostVodData } from '@/Interfaces/I_postManagement';
import { ChannelCategoryData, ChannelData, ChannelPostDataParam } from '@/Interfaces/I_vod';

import { getPostDetail } from '@/Redux/slices/ModalSlice';
import {
  deleteChannelPost,
  getAllChannel,
  getVodPost,
  postChannelPost,
  setSelectedChannel,
} from '@/Redux/slices/VodSlice';

import Modal, { ModalProps } from '@/Modules/modal';
import Table from '@/Modules/table/Table';
import TagLabel from '@/Modules/tagLabel';
import UserRoleLabel from '@/Modules/userRoleLabel';

import timeTranslate from '@/Utils/timeTranslate';
import { getFrontEndDomainByEnv } from '@/Axios/index';
import type { Moment } from 'moment';
import type { SelectProps } from 'antd';
import fileText from '../../images/icon/fileText.svg';

const { Search } = Input;
const { RangePicker } = DatePicker;
const DATE_RANGE_IN_MONTH = 6;

interface PosUIData {
  key: string;
  no: number;
  profileName: string;
  categories: {
    categoryId: string;
    categoryName: string;
    postUuid: string;
  };
  attachmentType: string;
  channel: {
    channelPinned: boolean;
    postUuid: string;
  };
  postTitle: {
    title: string;
    uuid: string;
  };
  vod: PostVodData;
  date: number;
  profileTag: string;
  like: number;
  comment: number;
  report: number;
  score: {
    popularityScore: number;
    extraScore: number;
  };
  scoreCalculate: {
    disabled: boolean;
    extraScore: number;
    postUuid: string;
  };
  pdp: {
    approved: boolean;
    postUuid: string;
  };
  action: { disabled: boolean; postUuid: string };
}

interface SorterDataType {
  score: number;
  date: number;
}

interface ExportCsvData {
  PostTitle: string;
  PostCreated: string;
  User: string;
  Like: number;
  Comment: number;
  Report: number;
  Score: number;
  Action: boolean;
}

type RangeValue = [Moment | null, Moment | null] | null;
const dateFrom = moment().subtract(30, 'days'); // set default date range 30 days
const dateTo = moment();
const defaultDateRange: [Moment, Moment] = [dateFrom, dateTo];

const VodChannel = () => {
  const dispatch = useAppDispatch();
  const userName = useAppSelector((state) => state.account.user.username);
  const vodPostData = useAppSelector((state) => state.vod.value.post);
  const loading = useAppSelector((state) => state.vod.loading);
  const allChannelData = useAppSelector((state) => state.vod.value.channels);
  const selectedChannel = useAppSelector((state) => state.vod.value.selectedChannel);
  const selectedChannelCategory = useAppSelector(
    (state) => state.vod.value.selectedChannelCategory,
  );

  const [channelOptions, setChannelOptions] = useState<SelectProps[]>([]);
  const selectChannel = useRef<number>(0);
  const [channelCategoryOptions, setChannelCategoryOptions] = useState<SelectProps[]>([]);
  const [selectedChannelCategories, setSelectedChannelCategories] = useState<SelectProps[]>([]);
  const selectChannelCategory = useRef<string>('');
  const [totalPage, setTotalPage] = useState(10);
  const [data, setData] = useState([] as PosUIData[]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchOption, setSearchOption] = useState('post');
  const [selectRowKeys, setSelectRowKeys] = useState([] as Array<React.Key>);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectRow, setSelectRow] = useState([] as ExportCsvData[]);
  const [modalProps, setModalProps] = useState<ModalProps>({});
  const [pageSize, setPageSize] = useState(10);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterDataType>>({
    order: 'descend',
    columnKey: 'score',
  });
  const [dates, setDates] = useState<RangeValue>(defaultDateRange);

  const pageName = 'Category';
  const searchOptions = [
    {
      value: 'post',
      label: 'Post Title',
    },
    {
      value: 'user',
      label: 'User',
    },
  ];

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }
    return (
      (dates[0] && current.diff(dates[0], 'months') >= DATE_RANGE_IN_MONTH) ||
      (!!dates[1] && dates[1].diff(current, 'months') >= DATE_RANGE_IN_MONTH)
    );
  };

  const searchPosts = () => {
    if (dates && dates[0] && dates[1]) {
      const sort =
        sortedInfo.column !== undefined
          ? (sortedInfo.columnKey as string)
              .replace(/[A-Z]/g, (letter) => `_${letter}`)
              .toUpperCase()
              .concat(sortedInfo.order === 'ascend' ? '_ASC' : '_DESC')
          : 'SCORE_DESC';
      dispatch(
        getVodPost({
          pageName,
          params: {
            title: searchOption === 'post' ? searchValue : null,
            user_name: searchOption !== 'post' ? searchValue : null,
            category:
              selectChannelCategory.current ||
              selectedChannelCategory.map((category) => category.categoryId).join(','),
            attachment_type: 'LONG_VIDEOS',
            sort,
            channel_id: selectedChannel.channelId,
            page_no: 0,
            page_size: pageSize,
            date_from: dates[0].format('YYYY-MM-DD'),
            date_to: dates[1].format('YYYY-MM-DD'),
          },
        }),
      );
    }
  };

  // dispatch get channels
  useEffect(() => {
    if (userName) {
      dispatch(getAllChannel());
    }
  }, [dispatch, userName]);

  useEffect(() => {
    if (userName && selectedChannelCategory.length && dates && dates[0] && dates[1]) {
      const sort =
        sortedInfo.column !== undefined
          ? (sortedInfo.columnKey as string)
              .replace(/[A-Z]/g, (letter) => `_${letter}`)
              .toUpperCase()
              .concat(sortedInfo.order === 'ascend' ? '_ASC' : '_DESC')
          : 'SCORE_DESC';
      dispatch(
        getVodPost({
          pageName,
          params: {
            title: searchOption === 'post' ? searchValue : null,
            user_name: searchOption !== 'post' ? searchValue : null,
            category:
              selectChannelCategory.current ||
              selectedChannelCategory.map((category) => category.categoryId).join(','),
            attachment_type: 'LONG_VIDEOS',
            channel_id: selectedChannel.channelId,
            sort,
            page_no: currentPage - 1,
            page_size: pageSize,
            date_from: dates[0].format('YYYY-MM-DD'),
            date_to: dates[1].format('YYYY-MM-DD'),
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectChannelCategory.current,
    searchValue,
    searchOption,
    currentPage,
    pageSize,
    sortedInfo.column,
    sortedInfo.columnKey,
    sortedInfo.field,
    sortedInfo.order,
  ]);

  useEffect(() => {
    if (allChannelData && allChannelData.length > 0) {
      const newMapData = allChannelData.map((item: ChannelData) => ({
        value: item.channelId,
        label: `${item.channelId} - ${item.channelName}`,
      }));
      setChannelOptions(newMapData);
    }
  }, [allChannelData]);

  useEffect(() => {
    if (selectedChannelCategory && selectedChannelCategory.length > 0) {
      const newMapData = selectedChannelCategory.map((item: ChannelCategoryData) => ({
        value: item.categoryId,
        label: item.categoryName,
      }));
      setChannelCategoryOptions(newMapData);
    }
  }, [selectedChannelCategory]);

  useEffect(() => {
    if (!loading && vodPostData.data) {
      const pageIndex = vodPostData.pagination.page * pageSize;
      const newMapData = vodPostData.data.map((item: PostManagementData, index: number) => ({
        key: item.uuid,
        no: index + 1 + pageIndex,
        profileName: item.user.name,
        categories: {
          categoryId: item.categories[0].categoryId,
          categoryName: item.categories[0].categoryName,
          postUuid: item.uuid,
        },
        attachmentType: item.attachmentType,
        channel: {
          channelPinned: item.channelPinned,
          postUuid: item.uuid,
        },
        postTitle: {
          title: item.title,
          uuid: item.uuid,
        },
        vod: {
          videoId: item.vod?.videoId || '',
          video480pUrl: item.vod?.video480pUrl || '',
          video720pUrl: item.vod?.video720pUrl || '',
          videoSmilUrl: item.vod?.videoSmilUrl || '',
          thumbnailId: item.vod?.thumbnailId || '',
          thumbnailUrl: item.vod?.thumbnailUrl || '',
          status: item.vod?.status || '',
          statusType: item.vod?.statusType || '',
          message: item.vod?.message || '',
          view: item.vod?.view || 0,
        },
        postCreated: item.creationDate,
        uuid: item.uuid,
        date: item.creationDate,
        profileTag: item.user.kocUserRole || '',
        pKey: item.user.hybrisId,
        like: item.statistics.likeCount,
        comment: item.statistics.commentCount,
        report: item.statistics.reportCount,
        score: {
          popularityScore: item.popularityScore,
          extraScore: item.extraScore,
        },
        pdp: {
          approved: item.pdp,
          postUuid: item.uuid,
        },
        scoreCalculate: {
          disabled: item.disabled,
          extraScore: item.extraScore,
          postUuid: item.uuid,
        },
        action: { disabled: item.disabled, postUuid: item.uuid },
      }));
      setData(newMapData);
      setTotalPage(vodPostData.pagination.total);
    }
  }, [vodPostData, loading, pageSize]);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
    },
    {
      title: 'Post uuid',
      dataIndex: 'uuid',
      key: 'uuid',
      width: '8%',
      ellipsis: {
        showTitle: false,
      },
      render: (uuid: string) => (
        <Tooltip placement="leftTop" title={uuid}>
          {uuid}
        </Tooltip>
      ),
    },
    {
      title: 'Profile Name',
      dataIndex: 'profileName',
      key: 'profileName',
      width: '20%',
    },
    {
      title: 'Video Title',
      dataIndex: 'postTitle',
      key: 'postTitle',
      className: 'pr-0',
      render: (item: { title: string; uuid: string }) => {
        const { title, uuid } = item;
        return (
          <Tooltip placement="leftTop" title={title}>
            <div className="flex items-center">
              <a 
                href= {`${getFrontEndDomainByEnv()}/post/${uuid}`}
                target="_blank"
                rel="noreferrer" 
                className="h-6 line-clamp-1 break-all mb-0">{title}</a>
              <img
                aria-hidden
                src={fileText}
                alt="photo"
                className="cursor-pointer ml-2"
                onClick={() => {
                  setModalProps({
                    modalType: 'postDetail',
                  });
                  setIsModalVisible(true);
                  dispatch(getPostDetail(uuid));
                }}
              />
            </div>
          </Tooltip>
        );
      },
      width: '20%',
    },
    {
      title: 'Video Uuid',
      dataIndex: 'vod',
      key: 'videoUuid',
      width: '8%',
      ellipsis: {
        showTitle: false,
      },
      render: (vod: { videoId: string }) => {
        const { videoId } = vod;
        return (
          <Tooltip placement="leftTop" title={videoId}>
            {videoId}
          </Tooltip>
        );
      },
    },
    {
      title: 'Profile Tag',
      dataIndex: 'profileTag',
      key: 'profileTag',
      width: '10%',
      ellipsis: {
        showTitle: false,
      },
      render: (profileTag: string) => (
        <UserRoleLabel
          userRoleStr={
            profileTag as
              | 'CUSTOMER'
              | 'OFFICIAL'
              | 'CONTENT_PROVIDER'
              | 'MERCHANT'
              | 'HKTV_SHOPS_MERCHANT'
              | 'YOUTUBER'
          }
        />
      ),
    },
    {
      title: 'Publish Created',
      dataIndex: 'date',
      key: 'date',
      width: '10%',
      render: (date: number) => <div className="flex">{timeTranslate(date)}</div>,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
    },
    {
      title: 'Top 20 Videos',
      dataIndex: 'channel',
      key: 'channelPinned',
      width: '10%',
      render: (channel: { channelPinned: boolean; postUuid: string }) => {
        const { channelPinned, postUuid } = channel;
        const param = {
          channelId: selectedChannel.channelId,
          postUuid: [postUuid],
        } as ChannelPostDataParam;
        return (
          <Popconfirm
            title={`Confirm to ${channelPinned ? 'unpin' : 'pin'}?`}
            onConfirm={() =>
              channelPinned ? dispatch(deleteChannelPost(param)) : dispatch(postChannelPost(param))
            }
            okText="Yes"
            cancelText="No"
          >
            <TagLabel
              labelType="status"
              statusType={channelPinned ? 'ENABLE' : 'DISABLE'}
              showDescription={false}
            />
          </Popconfirm>
        );
      },
    },
    {
      title: 'No. of view',
      dataIndex: 'vod',
      key: 'view_count',
      width: '10%',
      render: (vod: { view: number }) => {
        const { view } = vod;
        return (
          <Tooltip placement="leftTop" title={view} showArrow={false}>
            <p className="h-6 line-clamp-1 break-all m-0">{view}</p>
          </Tooltip>
        );
      },
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'view_count' ? sortedInfo.order : null,
    },
  ];

  const exportCsv = useCallback((selectedRows: PosUIData[]) => {
    const filterData = selectedRows
      .filter((item: PosUIData) => item !== undefined)
      .map((item: PosUIData) => ({
        PostTitle: item.postTitle.title,
        PostCreated: `${timeTranslate(item.date)}`,
        User: item.profileName,
        Like: item.like,
        Comment: item.comment,
        Report: item.report,
        Score: item.score.popularityScore,
        Action: item.action.disabled,
      }));
    setSelectRow(filterData);
  }, []);
  return (
    <>
      <div className="h-auto bg-white p-6 pb-2">
        <div className="flex items-center pb-4 justify-end">
          <div className="pr-4">
            <RangePicker
              inputReadOnly
              disabledDate={disabledDate}
              defaultValue={defaultDateRange}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              disabled={loading}
            />
          </div>
          <Button
            disabled={loading || !dates || !dates[0] || !dates[1] || !selectChannel.current}
            onClick={searchPosts}
          >
            Search
          </Button>
        </div>
        <div className="flex items-center pb-4 justify-between">
          <div className="flex">
            {channelOptions.length > 0 && (
              <Select
                style={{ width: 600 }}
                options={channelOptions}
                placeholder="選擇頻道"
                defaultValue={selectedChannel.channelId}
                onChange={(value) => {
                  selectChannel.current = value;
                  setSelectedChannelCategories([]);
                  selectChannelCategory.current = '';
                  dispatch(setSelectedChannel(value));
                }}
              />
            )}
          </div>
          <div className="flex">
            <Select
              mode="multiple"
              value={selectedChannelCategories}
              style={{ width: 300 }}
              options={channelCategoryOptions}
              placeholder="選擇分類"
              onChange={(value) => {
                setSelectedChannelCategories(value);
                selectChannelCategory.current = value.join(',');
              }}
            />
          </div>
          <div className="flex items-center">
            <div className="pr-4">
              <Select
                style={{ width: 110 }}
                defaultValue={searchOption}
                options={searchOptions}
                onChange={(value) => {
                  setSearchOption(value);
                }}
                bordered={false}
              />
            </div>
            <Search
              placeholder="input text"
              allowClear
              onSearch={(value) => {
                setSearchValue(value.trim());
                setCurrentPage(1);
              }}
              style={{ width: 264 }}
              className="pr-4"
              disabled={loading}
            />
            <Button disabled={selectRowKeys.length === 0 && loading}>
              <CSVLink uFEFF filename="download.csv" data={selectRow} target="_blank">
                Generate CSV
              </CSVLink>
            </Button>
          </div>
        </div>
        {!loading && data.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              total: totalPage,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              defaultPageSize: pageSize,
              defaultCurrent: 1,
              showSizeChanger: true,
              current: currentPage,
              onChange: (page: number, _selectPageSize: number) => {
                setCurrentPage(page);
              },
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
            }}
            rowSelection={{
              preserveSelectedRowKeys: true,
              selectedRowKeys: selectRowKeys,
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectRowKeys(selectedRowKeys);
                exportCsv(selectedRows as PosUIData[]);
              },
            }}
            onChange={(pagination, filters, sorter) => {
              const sorterInfo = sorter as SorterResult<SorterDataType>;
              setSortedInfo(sorterInfo);
            }}
          />
        )}
      </div>
      <Modal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} {...modalProps} />
    </>
  );
};
export default VodChannel;
