/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './modal.scss';

import { Button, Modal as ModalAnt, ModalProps as ModalAntProps } from 'antd';

import ReportRecord from '@/Components/ReportRecord';
import UserReportRecord from '@/Components/UserReportRecord';

import { VIDEO_PLAYER } from '@/Interfaces/I_vod';
import PostDetailCard from '../postDetailCard';

export interface ModalProps extends ModalAntProps {
  /**
   * modal type (reason, postDetail, record)
   */
  modalType?: 'reason' | 'postDetail' | 'reportRecord' | 'userRecord';
  /**
   * title
   */
  title?: string | React.ReactNode;
  /**
   * Whether the modal dialog is visible or not
   */
  visible?: boolean;
  /**
   * content
   */
  content?: React.ReactNode;
  /**
   * data
   */
  data?: any;
  /**
   * Whether to close the modal dialog when the mask (area outside the modal) is clicked
   */
  maskClosable?: boolean;
  /**
   * Specify a function that will be called when the user clicks the Cancel button.
   */
  onCancel?: (values: any) => void;
  /**
   * Specify a function that will be called when the user clicks the OK button.
   */
  onOk?: (values: any) => void;
}

const Modal = ({
  visible,
  title,
  maskClosable = true,
  onCancel,
  onOk,
  modalType = 'reason',
  content,
  data,
  ...props
}: ModalProps) => {
  let modalStyle = {};
  let modalContent;
  const pauseVideoplayerAndCancel = (e: unknown) => {
    const videoElement = document.getElementById(VIDEO_PLAYER) as HTMLVideoElement;
    if (
      modalType === 'postDetail' &&
      videoElement &&
      videoElement.children[0] instanceof HTMLVideoElement
    ) {
      (videoElement.children[0] as HTMLVideoElement).pause();
    }

    if (typeof onCancel === 'function') {
      onCancel(e);
    }
  };

  switch (modalType) {
    case 'reason':
      modalStyle = { width: 572, mask: false, okText: 'Confirm' };
      break;
    case 'postDetail':
      modalStyle = {
        width: 848,
        title: 'Post Details',
        bodyStyle: { padding: 0 },
        footer: <Button onClick={pauseVideoplayerAndCancel}>Back</Button>,
      };
      modalContent = <PostDetailCard />;
      break;
    case 'reportRecord':
      modalStyle = {
        width: 856,
        title: 'Report Record',
        bodyStyle: { padding: 0 },
        footer: null,
      };
      modalContent = <ReportRecord {...data} />;
      break;
    case 'userRecord':
      modalStyle = {
        width: 856,
        title: 'User Report Record',
        bodyStyle: { padding: 0 },
        footer: <Button onClick={onCancel}>Back</Button>,
      };
      modalContent = <UserReportRecord {...data} />;
      break;
    default:
      break;
  }

  return (
    <ModalAnt
      className="modal--root"
      visible={visible}
      title={title}
      maskClosable={maskClosable}
      onCancel={pauseVideoplayerAndCancel}
      onOk={onOk}
      centered
      {...modalStyle}
      {...props}
    >
      {content || modalContent}
    </ModalAnt>
  );
};
export default Modal;
