const timeTranslate = (timeString: number, detail = false) => {
  let timeStamp = timeString;
  if (timeString?.toString().length === 10) {
    timeStamp = timeString * 1000;
  }
  const DD = new Date(timeStamp).getDate().toString().padStart(2, '0');
  const MM = (new Date(timeStamp).getMonth() + 1).toString().padStart(2, '0');
  const YYYY = new Date(timeStamp).getFullYear();
  const HH = new Date(timeStamp).getHours().toString().padStart(2, '0');
  const MIN = new Date(timeStamp).getMinutes().toString().padStart(2, '0');
  if (detail) return `${YYYY}-${MM}-${DD} ${HH}:${MIN}`;
  return `${YYYY}-${MM}-${DD}`;
};

export default timeTranslate;
