import { PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  PostReportParam,
  ReasonParam,
  ReportRecordParam,
  UserReportRecordParam
} from '@/Interfaces/I_modal';

import {
  appendReportRecord,
  appendUserReportRecord,
  getPostDetail,
  getReasons,
  getReportRecord,
  getUserReportRecord,
  postReport,
  setPostDetail,
  setPostReport,
  setReasons,
  setReportRecord,
  setUserReportRecord
} from '@/Slices/ModalSlice';

import {
  fetchPostDetail,
  fetchReasons,
  fetchReportRecord,
  fetchUserReportRecord,
  postReportAPI
} from '@/Axios/api/modal';

function* handlePostReport(action: PayloadAction<PostReportParam>) {
  try {
    const data: AxiosResponse = yield call(postReportAPI, action.payload);
    if (data.status === 201) {
      let content = '';
      if (action.payload.actionType === 'DISABLE') {
        content = 'The post is blocked.';
      }
      if (action.payload.actionType === 'ENABLE') {
        content = 'The post is released.';
      }
      if (action.payload.actionType === 'IGNORE') {
        content = 'The report is ignored.';
      }
      message.success({
        content,
        duration: 3,
        style: {
          marginTop: '120px',
        },
      });
      yield put(setPostReport('success'));
    } else {
      message.error({
        content: 'Error, please try again.',
        duration: 3,
        style: {
          marginTop: '120px',
        },
      });
      yield put(setPostReport('error'));
    }
  } catch (e) {
    message.error({
      content: 'Error, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
    yield put(setPostReport(e));
  }
}

function* handleGetPostDetail(action: PayloadAction<string>) {
  try {
    const data: AxiosResponse = yield call(fetchPostDetail, action.payload);
    yield put(setPostDetail(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleGetReportRecord(action: PayloadAction<ReportRecordParam>) {
  try {
    const data: AxiosResponse = yield call(fetchReportRecord, action.payload);
    yield put(action.payload.append ? appendReportRecord(data) : setReportRecord(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleGetUserReportRecord(action: PayloadAction<UserReportRecordParam>) {
  try {
    const data: AxiosResponse = yield call(fetchUserReportRecord, action.payload);
    yield put(action.payload.append ? appendUserReportRecord(data) : setUserReportRecord(data));
  } catch (e) {
    // console.log(e);
  }
}

function* handleGetReasons(action: PayloadAction<ReasonParam>) {
  try {
    const data: AxiosResponse = yield call(fetchReasons, action.payload);
    yield put(setReasons(data));
  } catch (e) {
    // console.log(e);
  }
}

export function* watchPostReport() {
  yield takeEvery(postReport.type, handlePostReport);
}
export function* watchGetPostDetail() {
  yield takeEvery(getPostDetail.type, handleGetPostDetail);
}
export function* watchGetReportRecord() {
  yield takeEvery(getReportRecord.type, handleGetReportRecord);
}
export function* watchGetUserReportRecord() {
  yield takeEvery(getUserReportRecord.type, handleGetUserReportRecord);
}
export function* watchGetReasons() {
  yield takeEvery(getReasons.type, handleGetReasons);
}
